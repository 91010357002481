
import { computed, defineComponent, reactive, toRefs, watch } from 'vue';
import { getSmsCaptcha } from '@/api/user/login';
import { message, notification } from 'ant-design-vue';
import { useForm } from 'ant-design-vue/es/form';
import { LockOutlined, MailOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons-vue';
import { AxiosError } from 'axios';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  GET_CUSTOME_DATA_USER,
  GET_INFO,
  GET_MY_MENU_DATA_LIST,
  GET_NO_READ_NUMBER,
  GET_NO_RECEIVE_NUM,
  GET_USER,
  LOGIN,
} from '@/store/modules/user/actions';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Login',
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const currentLang = computed(() => store.getters['app/lang']);
    const state = reactive({
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      isLoginError: false,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,

      time: 60,
      smsSendBtn: false,
    });

    const handleUsernameOrEmail = (rule: any, value: any) => {
      return new Promise(resolve => {
        const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
        if (regex.test(value)) {
          state.loginType = 0;
        } else {
          state.loginType = 1;
        }
        resolve(state.loginType);
      });
    };
    const modelRef = reactive({
      rememberMe: true,
      username: '',
      password: '',
      mobile: '',
      captcha: '',
      currentLang: currentLang.value,
    });
    const rulesRef = reactive({
      rememberMe: undefined,
      username: [
        { required: true, message: t('请输入帐户名或邮箱地址'), type: 'string' },
        { validator: handleUsernameOrEmail, trigger: 'change' },
      ],
      password: [
        { required: true, message: t('请输入密码'), type: 'string', trigger: ['blur', 'change'] },
      ],
      mobile: [
        {
          required: true,
          // pattern: /^1[3456789]\d{9}$/,
          message: t('请输入正确的手机号'),
          trigger: ['blur', 'change'],
        },
      ],
      captcha: [{ required: true, message: t('请输入验证码') }],
    });
    const { validateInfos, validate, resetFields } = useForm(modelRef, rulesRef);

    const handleTabClick = (key: string) => {
      state.customActiveKey = key;
      resetFields();
    };

    const requestFailed = (err: AxiosError) => {
      console.log('requestFailed', err?.response?.data?.errorMessage);
      state.isLoginError = true;
      // notification.error({
      //   message: '错误',
      //   description: ((err.response || {}).data || {}).errorMessage || '请求出现错误，请稍后再试',
      //   duration: 4,
      // });
    };

    const loginSuccess = () => {
      router.push({ path: '/workplace' });
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: t('欢迎'),
          description: t('欢迎回来'),
        });
      }, 1000);
      state.isLoginError = false;
    };

    const getCaptcha = (e: Event) => {
      e.preventDefault();

      validate('mobile')
        .then(values => {
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);

          const hide = message.loading('验证码发送中..', 0);
          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500);
              notification.success({
                message: t('提示'),
                description: '验证码获取成功，您的验证码为：' + res.captcha,
                duration: 8,
              });
            })
            .catch(err => {
              setTimeout(hide, 1);
              clearInterval(interval);
              state.time = 60;
              state.smsSendBtn = false;
              requestFailed(err);
            });
        })
        .catch(err => {
          console.log('err', err);
        });
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      const validateNames =
        state.customActiveKey === 'tab1' ? ['username', 'password'] : ['mobile', 'captcha'];
      state.loginBtn = true;
      validate(validateNames)
        .then(values => {
          console.log('values', values);
          store
            .dispatch(`user/${LOGIN}`, {
              ...values,
              username: values.username.replace(/(^\s*)|(\s*$)/g, ""),
              type: state.customActiveKey === 'tab1',
            })
            .then((res: any) => {
              store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                org_id: Number(res.org_list.data[0].id),
              });
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              store
                .dispatch(`user/${GET_MY_MENU_DATA_LIST}`, { org_id: Number(current_org?.id) })
                .then(() => {
                  store.dispatch(`user/${GET_INFO}`).then(() => {
                    store.dispatch(`user/${GET_USER}`, { org_id: Number(current_org?.id) });
                    store.dispatch(`user/${GET_NO_READ_NUMBER}`, { org_id: Number(current_org?.id) });
                    store.dispatch(`user/${GET_NO_RECEIVE_NUM}`,
                      {
                        org_id: Number(current_org?.id),
                        is_no_service: true,
                        is_closed: false,
                        is_auto_reply: false,
                      });
                    loginSuccess();
                  });
                });
            })
            .catch(err => {
              requestFailed(err);
            })
            .finally(() => {
              state.loginBtn = false;
            });
        })
        .catch((/*err*/) => {
          // 屏蔽错误处理
          // requestFailed(err);
          state.loginBtn = false;
        });
    };
    // this.loginBtn = false;
    // this.stepCaptchaVisible = false;
    watch(() => modelRef.currentLang,
      () => {
        store.dispatch('app/SET_LANG', modelRef.currentLang);
      });
    return {
      ...toRefs(state),
      t,
      modelRef,
      validateInfos,

      handleTabClick,
      handleSubmit,
      getCaptcha,
    };
  },
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined,
  },
});
